// Dont use any imports from Vue here (or bus) - the service is use by GTM.service which is loaded in bootstrap code.
import { localStorageService } from '@/core/storage.service';

const LOCAL_STORAGE_KEY = 'eetgroup-eet-auth';
const LOCAL_STORAGE_KEY_FOR_PREVIOUS = 'eetgroup-eet-auth-previous';

export enum UserGroup {
    System = 'System',
    Administrator = 'Administrator',
    Trusted = 'Trusted',
    Normal = 'Normal',
    Viewer = 'Viewer',
    PunchOut = 'PunchOut',
    Support = 'Support'
}

export interface UserData {
    businessEntityName: string;
    contactKey: string;
    contactHash: string;
    customerId: string;
    customerKey: string;
    customerNo: string;
    companyName: string;
    salesCategory: string;
    userId: string;
    webUserId: string;
    role: UserGroup;
    blocking: 'CanLoginButCannotPlaceOrder' | 'None' | 'Complete';
    impersonate: string;
    userName: string;
    impersonatorEmail?: string;
    staff: string;
    userSegment?: string;
}

let _token: string | null = localStorageService.getItem(LOCAL_STORAGE_KEY);
let _tokenPayload: UserData | null = null;
setTokenPayload(_token);

function clearToken(): void {
    _token = null;
    localStorageService.removeItem(LOCAL_STORAGE_KEY);
    setTokenPayload(null);
}

function setToken(token: string): void {
    _token = token;
    localStorageService.setItem(LOCAL_STORAGE_KEY, token);
    localStorageService.setItem(LOCAL_STORAGE_KEY_FOR_PREVIOUS, token);
    setTokenPayload(token);
}

function getToken(): string | null {
    return _token;
}

function getPreviousToken(): string | null {
    return localStorageService.getItem(LOCAL_STORAGE_KEY_FOR_PREVIOUS);
}

function getUserData(fallbackToPrevious = false): UserData | null {
    if (fallbackToPrevious) return _tokenPayload || parseJwt(localStorageService.getItem(LOCAL_STORAGE_KEY_FOR_PREVIOUS));
    return _tokenPayload;
}

function setTokenPayload(token: string | null) {
    if (token) {
        _tokenPayload = parseJwt(token);
    } else {
        _tokenPayload = null;
    }
}

function parseJwt(token: string | null): UserData | null {
    if (!token) return null;

    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        // eslint-disable-next-line
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const parsedToken = JSON.parse(jsonPayload);
        const userData = {};
        Object.keys(parsedToken).forEach(key => {
            const pascalCasedKey = key.charAt(0).toLowerCase() + key.slice(1);
            userData[pascalCasedKey] = parsedToken[key];
        });
        return userData as UserData;
    } catch {
        localStorageService.removeItem(LOCAL_STORAGE_KEY);
        localStorageService.removeItem(LOCAL_STORAGE_KEY_FOR_PREVIOUS);
        return null;
    }
}

export default {
    clearToken,
    setToken,
    getToken,
    getPreviousToken,
    getUserData
};
