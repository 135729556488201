import Vue, { CreateElement, Component } from 'vue';
import translateFilter from '@/core/translation/translate.filter';
import password from './custom-rules/password';
import { ValidationRule } from 'vee-validate/dist/types/types';
import { ValidationObserver } from 'vee-validate';
import Api from '@/project/http/Api.service';
import debounce from 'lodash/debounce';

const loadedState = Vue.observable({
    validationProvider: null as Component | null,
    validationObserver: null as Component | null
});
let initCalled = false;

async function init() {
    if (initCalled) return;
    initCalled = true;

    const veeValidateAsyncLoad = () => import(/* webpackPrefetch: true, webpackChunkName: 'vee-validate' */ 'vee-validate');
    const veeValidateRulesAsyncLoad = () => import(/* webpackPrefetch: true, webpackChunkName: 'vee-validate' */ 'vee-validate/dist/rules');
    const [
        { extend, setInteractionMode, ValidationObserver, ValidationProvider },
        { email, required, is, min, max, regex }] = await Promise.all([veeValidateAsyncLoad(), veeValidateRulesAsyncLoad()]);

    setupNewRule('required', required, 'Validation.Required');
    setupNewRule('email', email, 'Validation.Email');
    setupNewRule('password', password.passwordValidator, 'Validation.Password');
    setupNewRule('same-password', is, 'Validation.PasswordMustMatch');
    setupNewRule('min', min, 'Validation.MinLength', 'length');
    setupNewRule('max', max, 'Validation.MaxLength', 'length');
    setupNewRule('regex', regex, 'Validation.Regex');
    setupNewRule('url', {
        validate: value => {
            if (value) {
                return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
            }
            return false;
        }
    } as ValidationRule, 'Validation.Url');

    setupNewRule('object-has-allowed', {
        validate: value => {
            if (value) {
                return value?.allowed === true;
            }
            return true;
        }
    }, 'Validation.ObjectHasAllowed');

    setupNewRule('streetname', {
        validate: value => {
            return validateStringHasChars(value);
        }
    } as ValidationRule, 'Validation.StreetName');

    setupNewRule('streetnumber', {
        validate: value => {
            return validateStringHasNumber(value);
        }
    } as ValidationRule, 'Validation.StreetNumber');

    setupNewRule('server-email', {
        validate: async(value: string) => {
            const response = await serverValidateEmailDebounced(value);
            if (response === undefined || response?.find(x => x.email === value && x.result === true)) {
                return true;
            } else {
                return false;
            }
        }
    } as ValidationRule, 'Validation.EmailServer');

    setInteractionMode('eager');
    loadedState.validationObserver = ValidationObserver;
    loadedState.validationProvider = ValidationProvider;
    const serverValidateEmail = async(value: string) => { return Api.commonFunctions.validateEmailAddress(value); };
    // For some reason, debounce does not work on validation-observer in <InputText> component, so debounce here to avoid too many requests.
    const serverValidateEmailDebounced = debounce(serverValidateEmail, 200);

    function setupNewRule(ruleName: string, rule: object, labelMapping: string, paramName: string | undefined = undefined) {
        const validationSchema: ValidationRule = ({
            ...rule,
            message: (field, params?: Record<string, any>) => translateFilter(labelMapping, paramName && params ? params[paramName] : undefined)
        });
        if (paramName) {
            validationSchema.params = [paramName];
        }
        extend(ruleName, validationSchema);
    }
};

function validateStringHasChars(value: string = ''): boolean {
    const regex = new RegExp(/\p{L}/, 'gu');
    const regmatch = value.match(regex);
    const match = regmatch && regmatch.length > 1;
    return match || false;
}
function validateStringHasNumber(value: string = ''): boolean {
    const regex = /\d/;
    const regmatch = value.match(regex);
    const match = regmatch && regmatch.length > 0;
    return match || false;
}
export function validationRuleAddressField(market: string): string[] {
    return []; // Temp for now, as there are errors with addresses and edit existing addresses.

    // For new addresses, validate that street has both chars and numbers - Only not if address country is Norway as few addresses do not have a street number in Norway: https://www.posten.no/en/sending/preparation/addressing
    // const rules = ['streetname'];
    // const marketsToIgnore: string[] = ['no', 'gb'];
    // if (marketsToIgnore.indexOf(market.toLowerCase()) === -1) {
    //     rules.push('streetnumber');
    // }
    // return rules;
}
const AsyncValidationProvider = Vue.component('AsyncValidationProvider', {
    created() {
        init();
    },
    render(h) {
        const element = loadedState.validationProvider || 'span';
        return h(element, { scopedSlots: this.$scopedSlots, attrs: this.$attrs, props: this.$props, on: this.$listeners }, this.$slots.default);
    }
});

const AsyncValidationObserver = Vue.component('AsyncValidationObserver', {
    created() {
        init();
    },
    methods: {
        validate(options: { silent?: boolean | undefined; } | undefined): Promise<boolean> {
            const silent = options?.silent;
            return (this.$children[0] as InstanceType<typeof ValidationObserver>).validate({ silent });
        },
        reset(): void {
            return (this.$children[0] as InstanceType<typeof ValidationObserver>).reset();
        }
    },
    render(h: CreateElement) {
        const element = loadedState.validationObserver || 'span';
        return h(element, { scopedSlots: this.$scopedSlots, attrs: this.$attrs, props: this.$props, on: this.$listeners }, this.$slots.default);
    }
});

Vue.component('ValidationProvider', AsyncValidationProvider);
Vue.component('ValidationObserver', AsyncValidationObserver);
