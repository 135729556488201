import Vue from 'vue';
import { blockResolver } from '@/core/app/componentResolver.service';

import BlockRender from './helpers/BlockRender.vue';
import BlockList from './helpers/BlockList.vue';

const HalfWidthBlocks = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './HalfWidthBlocks.vue');
const OnePlusTwoBlocks = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './OnePlusTwoBlocks.vue');
const HeroBannerBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './HeroBannerBlock.vue');
const AdnuntiusHeroBannerBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './AdnuntiusHeroBannerBlock.vue');
const UniqueSellingPointBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './UniqueSellingPointBlock.vue');
const UniqueSellingPointItem = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './UniqueSellingPointItem.vue');
const BrandsBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './BrandsBlock.vue');
const TextBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './TextBlock.vue');
const FullWidthTextBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './FullWidthTextBlock.vue');
const TextWithImageBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './TextWithImageBlock.vue');
const ProductRecommendationsBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './ProductRecommendationsBlock.vue');
const BrandRecommendationsBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './BrandRecommendationsBlock.vue');
const CategoryRecommendationsBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './CategoryRecommendationsBlock.vue');
const ProductListBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './ProductListBlock.vue');
const TabbedContentBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './TabbedContentBlock.vue');
const QuoteBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './QuoteBlock.vue');
const LatestNewsBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './LatestNewsBlock.vue');
const LatestCampaignsBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './LatestCampaignsBlock.vue');
const CategoryBlock = () => import(/* webpackPrefetch: true, webpackChunkName: 'basic-blocks' */ './categories/CategoryBlock.vue'); // Used on frontpage

// Lower priority - not in same chunk
const SimpleBannerBlock = () => import('./SimpleBannerBlock.vue');
const EmployeeBlock = () => import('./information/EmployeeBlock.vue');
const BecomeACustomerBlock = () => import('./information/BecomeACustomerBlock.vue');
const JotFormBlock = () => import('./JotFormBlock.vue');
const BecomeAResellerBlock = () => import('./information/BecomeAResellerBlock.vue');
const CancelNewsletterBlock = () => import('./newsletter/CancelNewsletterBlock.vue');
const SubscribeNewsletterBlock = () => import('./newsletter/SubscribeNewsletterBlock.vue');
const ProductGuideBlock = () => import(/* webpackChunkName: 'product-guide' */ './productguide/ProductGuideBlock.vue');
const ProductGuideConfigurationBlock = () => import(/* webpackChunkName: 'product-guide' */ './productguide/ProductGuideConfigurationBlock.vue');
const IframeBlock = () => import(/* webpackPrefetch: true */ './IframeBlock.vue');
const MapBlock = () => import('./google-maps/MapBlock.vue');
const CableGuideBlock = () => import(/* webpackChunkName: 'product-guide' */ './cableguide/CableGuideBlock.vue');
const StatisticsBlock = () => import('./statistics/StatisticsBlock.vue');
const CategoryTileBlock = () => import('./categories/CategoryTileBlock.vue');
const CodeBlock = () => import(/* webpackChunkName: 'code-block' */ './CodeBlock.vue');
const HeroBannerUniqueSellingPointsBlock = () => import('./HeroBannerUniqueSellingPointsBlock.vue');
const SimpleUniqueSellingPointsBlock = () => import('./SimpleUniqueSellingPointsBlock.vue');
const FaqBlock = () => import('./FaqBlock.vue');
const StepsBlock = () => import('./StepsBlock.vue');
const ProductSeriesBlock = () => import('./ProductSeriesBlock.vue');
const ProductGuideBrandsBlock = () => import('./productguide/StepProductGuide/ProductGuideBrandsBlock.vue');
const BrandProductGuideBlock = () => import('./productguide/StepProductGuide/BrandProductGuideBlock.vue');
const ProductGuideSelectorBlock = () => import('./productguide/StepProductGuide/ProductGuideSelectorBlock.vue');

const FallbackBlock = () => import('./FallbackBlock.vue');

export type BlockName =
    'halfWidthBlocks' |
    'halfWidthBlock' |
    'twoPlusOneBlocks' |
    'heroBanner' |
    'heroBannerWithUsp' |
    'personalizedContent' |
    'uniqueSellingPointBlock' |
    'brandsBlock' |
    'codeBlock' |
    'uniqueSellingPointItem' |
    'textBlock' |
    'fullWidthTextBlock' |
    'categoryBlock' |
    'jotFormBlock' |
    'becomeACustomerBlock' |
    'becomeAResellerBlock' |
    'cancelNewsletterBlock' |
    'subscribeNewsletterBlock' |
    'textWithImageBlock' |
    'productGuideBlock' |
    'productGuideConfigurationBlock' |
    'cableGuideBlock' |
    'iframeBlock' |
    'productRecommendationsBlock' |
    'categoryRecommendationsBlock' |
    'productListBlock' |
    'tabbedContentBlock' |
    'quoteBlock' |
    'map' |
    'latestNewsBlock' |
    'latestCampaignsBlock' |
    'categoryTileBlock' |
    'statisticsBlock' |
    'employeesBlock' |
    'brandRecommendationsBlock' |
    'faqBlock' |
    'stepsBlock' |
    'simpleUspBlock' |
    'productSeriesBlock' |
    'simpleBannerBlock' |

    'productGuideBrandsBlock' |
    'productGuideSelectorBlock' |
    'brandProductGuideBlock';

blockResolver.register<BlockName>('halfWidthBlocks', HalfWidthBlocks);
blockResolver.register<BlockName>('twoPlusOneBlocks', OnePlusTwoBlocks);
blockResolver.register<BlockName>('heroBanner', HeroBannerBlock);
blockResolver.register<BlockName>('heroBannerWithUsp', HeroBannerUniqueSellingPointsBlock);
blockResolver.register<BlockName>('simpleUspBlock', SimpleUniqueSellingPointsBlock);
blockResolver.register<BlockName>('personalizedContent', AdnuntiusHeroBannerBlock);
blockResolver.register<BlockName>('uniqueSellingPointBlock', UniqueSellingPointBlock);
blockResolver.register<BlockName>('brandsBlock', BrandsBlock);
blockResolver.register<BlockName>('uniqueSellingPointItem', UniqueSellingPointItem);
blockResolver.register<BlockName>('textBlock', TextBlock);
blockResolver.register<BlockName>('codeBlock', CodeBlock);
blockResolver.register<BlockName>('fullWidthTextBlock', FullWidthTextBlock);
blockResolver.register<BlockName>('categoryBlock', CategoryBlock);
blockResolver.register<BlockName>('jotFormBlock', JotFormBlock);
blockResolver.register<BlockName>('becomeACustomerBlock', BecomeACustomerBlock);
blockResolver.register<BlockName>('becomeAResellerBlock', BecomeAResellerBlock);
blockResolver.register<BlockName>('cancelNewsletterBlock', CancelNewsletterBlock);
blockResolver.register<BlockName>('subscribeNewsletterBlock', SubscribeNewsletterBlock);
blockResolver.register<BlockName>('textWithImageBlock', TextWithImageBlock);
blockResolver.register<BlockName>('productGuideBlock', ProductGuideBlock);
blockResolver.register<BlockName>('productGuideConfigurationBlock', ProductGuideConfigurationBlock);
blockResolver.register<BlockName>('cableGuideBlock', CableGuideBlock);
blockResolver.register<BlockName>('iframeBlock', IframeBlock);
blockResolver.register<BlockName>('productRecommendationsBlock', ProductRecommendationsBlock);
blockResolver.register<BlockName>('categoryRecommendationsBlock', CategoryRecommendationsBlock);
blockResolver.register<BlockName>('productListBlock', ProductListBlock);
blockResolver.register<BlockName>('tabbedContentBlock', TabbedContentBlock);
blockResolver.register<BlockName>('quoteBlock', QuoteBlock);
blockResolver.register<BlockName>('map', MapBlock);
blockResolver.register<BlockName>('latestNewsBlock', LatestNewsBlock);
blockResolver.register<BlockName>('latestCampaignsBlock', LatestCampaignsBlock);
blockResolver.register<BlockName>('categoryTileBlock', CategoryTileBlock);
blockResolver.register<BlockName>('statisticsBlock', StatisticsBlock);
blockResolver.register<BlockName>('employeesBlock', EmployeeBlock);
blockResolver.register<BlockName>('brandRecommendationsBlock', BrandRecommendationsBlock);
blockResolver.register<BlockName>('faqBlock', FaqBlock);
blockResolver.register<BlockName>('stepsBlock', StepsBlock);
blockResolver.register<BlockName>('productSeriesBlock', ProductSeriesBlock);
blockResolver.register<BlockName>('productGuideBrandsBlock', ProductGuideBrandsBlock);
blockResolver.register<BlockName>('brandProductGuideBlock', BrandProductGuideBlock);
blockResolver.register<BlockName>('productGuideSelectorBlock', ProductGuideSelectorBlock);
blockResolver.register<BlockName>('simpleBannerBlock', SimpleBannerBlock);

blockResolver.registerFallback(FallbackBlock);

Vue.component('BlockRender', BlockRender);
Vue.component('BlockList', BlockList);
