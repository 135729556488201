












































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import breakpointsState from '@/core/responsive/breakpoints/breakpointsState.observable';
import scrollService from '@/core/scroll/scroll.service';

@Component
export default class ReadMore extends Vue {
    @Prop({ default: 0, type: Number }) minHeight!: number;
    @Prop({ default: 0, type: Number }) minItems!: number;
    @Prop({ required: false, type: Number }) pageChunkSize!: number;

    /*
        Animation time in MS
    */
    @Prop({ default: 200, type: Number }) animationTime!: number;

    /*
        Percentage of height in px to alter the speed of animation
     */
    @Prop({ default: 0.20, type: Number }) animationTimeMod!: number;
    @Prop({ default: '', type: String }) buttonClass!: string;
    @Prop({ default: 'div', type: String }) tag!: string;
    @Prop({ default: '', type: String }) bodyClass!: string;
    @Prop({ default: false, type: Boolean }) expanded!: boolean;
    @Prop({ default: false, type: Boolean }) withFade!: boolean;
    @Prop({ default: false, type: Boolean }) useObserver!: boolean;
    @Prop({ default: false, type: [Boolean, Number] }) scrollToElementTopOnCollapse!: boolean | number;
    @Prop({ type: String }) scrollContainerSelector!: string;

    isContentOverflowing: boolean = false;
    showFullContent: boolean = this.expanded;
    animationDuration: number = this.animationTime;
    observer: any = null;
    currentChunkPage: number | null = null;
    initialContentOverflowing: boolean = false;

    $refs!: {
        body: HTMLDivElement;
    };

    init() {
        this.initialDetermineMode();
        this.determineMode();
        this.calculateAnimDuration();
    }

    mounted() {
        this.$nextTick(() => {
            this.init();

            if (this.useObserver) {
                this.observer = new MutationObserver((mutations: MutationRecord[], observer: MutationObserver) => {
                    this.init();
                });

                // Setup the observer
                this.observer.observe(
                    this.$refs.body,
                    { childList: true, subtree: true }
                );
            }
        });
    }

    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }

    setAutoHeight(force?: boolean) {
        if (this.showFullContent || force) {
            this.$refs.body.style.height = 'auto';
        }
    }

    initialDetermineMode(): void {
        const minHeight = this.calculateMinHeight();
        this.initialContentOverflowing = this.$refs.body.scrollHeight > minHeight;
    }

    determineMode(): void {
        if (!this.$refs.body) return;
        const minHeight = this.calculateMinHeight();

        // Only show "show more" if needed. Hide gradient if not relevant.
        this.isContentOverflowing = this.$refs.body.scrollHeight > minHeight;
        if (this.isContentOverflowing) {
            // the height of body is larger than "min height", so "read more" feature makes sense.
            this.showFullContent = false;
        }
        if (this.showFullContent) {
            const expandedHeight = this.$refs.body.scrollHeight;
            this.$refs.body.style.height = expandedHeight + 'px';
        } else if (this.isContentOverflowing) {
            this.$refs.body.style.height = minHeight + 'px';
        } else {
            this.setAutoHeight(true);
        }
    }

    externalEventHappenedReconsiderMode(resetCurrentExpandedHeight?: boolean) {
        if (resetCurrentExpandedHeight) {
            this.setAutoHeight(true);
            this.showFullContent = false;
            this.currentChunkPage = null;
        }
        this.$nextTick(() => {
            this.determineMode();
        });
    }

    calculateAnimDuration() {
        if (this.isContentOverflowing) {
            const animationTime = this.animationTime + (this.calculateMinHeight(true) * this.animationTimeMod);
            this.animationDuration = animationTime;
        }
    }

    get getStyle(): Partial<CSSStyleDeclaration> {
        return {
            transitionDuration: `${this.animationDuration}ms`
        };
    }

    calculateMinHeight(forced = false): number {
        if (this.minItems || forced) {
            const items = this.$slots.default;
            if (items && items.length > this.minItems) {
                let totalHeight = 0;
                const itemsToShowByPage = this.pageChunkSize && this.currentChunkPage ? Math.max((this.minItems), (this.pageChunkSize * this.currentChunkPage)) : this.minItems;
                const itemsToShow = Math.min(itemsToShowByPage, items.length);
                for (let i = 0; i < itemsToShow; ++i) {
                    if (!forced && i >= itemsToShow) break;

                    totalHeight += (items[i].elm as HTMLElement)?.scrollHeight ?? 0;
                }

                return totalHeight;
            } else { // Do not show overflow if there is fewer items than allowed to show
                return Number.MAX_VALUE;
            }
        }
        return this.minHeight;
    }

    get expandWithMinItemsWillShowNMore(): number {
        if (!this.minItems) return 0;
        const items = this.$slots.default;
        if (this.pageChunkSize) {
            const currentChunkPage = this.currentChunkPage || 0;
            const itemsShowing = (this.minItems || 0) + (currentChunkPage * this.pageChunkSize);
            return (items?.length ?? 0) - itemsShowing;
        }
        return (items?.length ?? 0) - this.minItems;
    }

    handleClickExpandCollapseButton() {
        if (this.pageChunkSize) {
            let modeForTracking: 'expand' | 'expand-more' | 'collapse' = this.showFullContent ? 'collapse' : 'expand';
            const items = this.$slots.default;
            const currentChunkPage = this.currentChunkPage || 0;
            const nextPage = currentChunkPage + 1;
            const itemsToShow = (this.minItems || 0) + (nextPage * this.pageChunkSize);
            if (items && items.length > (itemsToShow - this.pageChunkSize)) {
                this.currentChunkPage = nextPage;
                this.showFullContent = true;
                modeForTracking = 'expand-more';
            } else {
                this.currentChunkPage = null;
                this.showFullContent = false;
                this.triggerScrollOnCollapse();
                modeForTracking = 'collapse';
            }
            const expandedHeight = this.$refs.body.scrollHeight;
            this.$refs.body.style.height = expandedHeight + 'px';
            setTimeout(() => {
                this.$refs.body.style.height = this.calculateMinHeight() + 'px';
            }, 0);
            this.$nextTick(() => this.determineMode());
            this.$emit('click', modeForTracking, this.currentChunkPage);
        } else {
            this.toggleShowFullContent();
        }
    }

    handleClickCollapsePageChunks() {
        if (this.currentChunkPage) {
            this.currentChunkPage = null;
            this.showFullContent = false;
            this.triggerScrollOnCollapse();
            const expandedHeight = this.$refs.body.scrollHeight;
            this.$refs.body.style.height = expandedHeight + 'px';
            setTimeout(() => {
                this.$refs.body.style.height = this.calculateMinHeight() + 'px';
            }, 0);
        }
    }

    toggleShowFullContent(state?: boolean): void {
        this.showFullContent = typeof state === 'boolean' ? state : !this.showFullContent;

        this.$emit('click', this.showFullContent ? 'expand' : 'collapse', this.currentChunkPage);

        const expandedHeight = this.$refs.body.scrollHeight;
        this.$refs.body.style.height = expandedHeight + 'px';

        // Set a timeout to allow the browser to calculate the transition
        if (!this.showFullContent) {
            setTimeout(() => {
                this.$refs.body.style.height = this.calculateMinHeight() + 'px';
            }, 0);
            this.triggerScrollOnCollapse();
        }
    }

    triggerScrollOnCollapse() {
        if (this.scrollToElementTopOnCollapse) {
            const offset = typeof this.scrollToElementTopOnCollapse === 'number' ? this.scrollToElementTopOnCollapse : -50;
            const scrollContainer = (this.scrollContainerSelector && document.querySelector(this.scrollContainerSelector) as HTMLElement) || undefined;
            scrollService.scrollToElement(this.$el as HTMLElement, offset, this.animationDuration / 2, scrollContainer);
        }
    }

    open() {
        this.toggleShowFullContent(true);
    }

    close() {
        this.toggleShowFullContent(false);
    }

    get activeBreakpoint() {
        return breakpointsState.activeBreakpoint;
    }

    @Watch('activeBreakpoint')
    onBreakpointChange(activeBp: string) {
        this.initialDetermineMode();
        this.determineMode();
        this.calculateAnimDuration();
    }

    @Watch('minItems')
    onMinItemsChange() {
        this.$nextTick(() => {
            this.determineMode();
            this.calculateAnimDuration();
            this.initialDetermineMode();
        });
    }
}
