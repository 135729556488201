import httpService from '@/core/http/http.service';
import { createUrlFactory } from '@/project/http/ApiHelper.service';
import { EmailValidationResultViewObject } from '@/types/apiServerContract';

export class CommonFunctionsController {
    private readonly urlFactory = createUrlFactory('commonfunctions');

    validateEmailAddress(email: string): Promise<EmailValidationResultViewObject[]> {
        return httpService.get(this.urlFactory('validateemailaddress'), { emailAddresses: email });
    }
}
