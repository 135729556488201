import log from '@/core/logging.service';
import { router } from '@/router/index';
import serverContextService from '@/core/serverContext.service';

export function hardReload() {
    log.info('Hard reload');
    window.location.reload();
}

export function softRedirect(url: string) {
    log.info(`Soft redirect. Url: ${url}`);
    // If url starts with 'da-dk', do not redirect to 'da-dk/da-dk/rest-of-url'. Handle link for frontpage first.
    if (serverContextService.siteInfo.url && url === serverContextService.siteInfo.url) {
        url = '/';
    } else if (serverContextService.siteInfo.url && url.startsWith(serverContextService.siteInfo.url)) {
        url = url.replace(serverContextService.siteInfo.url, '');
    }
    router.replace(url);
}

export function hardRedirect(url: string) {
    log.debug(`Hard redirect. Url: ${url}`);
    window.location.href = url;
}
