import serverContextService from '@/core/serverContext.service';
import { Location, RawLocation } from 'vue-router';

function hasCulturePrefix(to: string): boolean {
    return to.startsWith(serverContextService.siteInfo.url!);
}
function prependCulturePrefix(url: string): string {
    return serverContextService.siteInfo.url! + url;
}
function withoutCulturePrefix(url: string): string {
    if (url.indexOf(serverContextService.siteInfo.url!) === 0) {
        return url.substr((serverContextService.siteInfo.url!.length));
    }

    return url;
}

function removeTrailingSlashAndLowercase(location: string): { path: string, hash?: string } {
    const [pathWithoutHash, hash] = location.split('#');
    const withoutTrailingSlash = pathWithoutHash.endsWith('/') && pathWithoutHash.length > 1 ? pathWithoutHash.slice(0, -1) : pathWithoutHash;
    // Lowercase path, but not query. We could have facets that are important to keep in original casing.
    let [path, query] = withoutTrailingSlash.split('?');

    path = path.toLowerCase().replaceAll(' ', '-').replaceAll('%20', '-');
    if (query) {
        path = path + '?' + query;
    }

    return {
        path: path,
        hash: hash || undefined
    };
}
function removeHostFromInternalLinks(to:RawLocation): string | false {
    const path = isLocation(to) ? to.path : to;
    if (!path) return false;

    let url;

    try {
        url = new URL(path);
        return url?.pathname + url.search || path;
    } catch (_) {
        return false;
    }
}

function isOutgoingUrl(to: RawLocation): boolean {
    const path = isLocation(to) ? to.path : to;
    if (!path) return false;

    const external = RegExp('^((f|ht)tps?:)?//(?!' + location.host + ')');
    const isExternal = external.test(path);

    return isExternal || isExternalScheme(path);
}

function isLocation(to: RawLocation): to is Location {
    return typeof to === 'object';
}

// if a href points to external scheme e.g. (mailto:)
function isExternalScheme(href: string): boolean {
    const externalScheme = RegExp('^(?:javascript|data|chrome|mailto|tel|sms|callto|mms|skype):.+$');
    return externalScheme.test(href);
}

function isFrontpagePath(to: string): boolean {
    return to.endsWith(serverContextService.siteInfo.url!);
}

function removeNonPrintableChars(str: string): string {
    // Use the replace method with a regular expression to remove non-printable characters
    // The regular expression /[\x00-\x1F]/g matches any character that is in the range of 0 - 19 ASCII characters
    // (hexadecimal values from 00 to 0x1F)

    // eslint-disable-next-line no-control-regex
    return str.replace(/[\x00-\x1F]/g, '');
}
export default {
    hasCulturePrefix,
    prependCulturePrefix,
    withoutCulturePrefix,
    removeTrailingSlashAndLowercase,
    removeHostFromInternalLinks,
    isFrontpagePath,
    isLocation,
    isExternalScheme,
    isOutgoingUrl,
    removeNonPrintableChars
};
