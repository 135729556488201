import { RouteConfig } from 'vue-router';
import { PageName } from '@/router/routes/constants';
import { RouterMetaData } from '@/core/app/clientPageHandler';
import { Permission } from '@/project/auth/permission.service';
import dictionaryService from '@/core/translation/dictionary.service';

export const MyAccountRoutes: RouteConfig =
{
    path: '/my-account',
    name: PageName.MY_ACCOUNT,
    redirect: { name: PageName.MY_ACCOUNT_PROFILE },
    component: () => import(
        /* webpackChunkName: 'myaccount' */
        '@/project/app/client-pages/my-account/MyAccountPage.vue'),
    meta: {
        isPublic: false,
        breadcrumb: [
            {
                name: dictionaryService.get('MyAccount.Navigation.Title'),
                page: PageName.MY_ACCOUNT
            }
        ]
    } as RouterMetaData,
    children: [
        {
            path: 'profile',
            name: PageName.MY_ACCOUNT_PROFILE,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/profile/MyAccountProfile.vue'),
            meta: {
                permission: Permission.VIEW_MYACCOUNT,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Profile'),
                        page: PageName.MY_ACCOUNT_PROFILE
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'product-backlog',
            name: PageName.MY_ACCOUNT_PRODUCT_BACKLOG,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/product-backlog/ProductBacklog.vue'),
            meta: {
                permission: Permission.VIEW_ORDER_STATUS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.ProductBacklog'),
                        page: PageName.MY_ACCOUNT_PRODUCT_BACKLOG
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'order-history',
            name: PageName.MY_ACCOUNT_ORDER_HISTORY,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/order/OrderHistory.vue'),
            meta: {
                permission: Permission.VIEW_ORDER_STATUS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.OrderHistory'),
                        page: PageName.MY_ACCOUNT_ORDER_HISTORY
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'order-history/detail/:orderId',
            name: PageName.MY_ACCOUNT_ORDER_HISTORY_DETAIL,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/order/OrderHistoryDetail.vue'),
            props: true,
            meta: {
                permission: Permission.VIEW_ORDER_STATUS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.OrderHistory'),
                        page: PageName.MY_ACCOUNT_ORDER_HISTORY
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.OrderDetail'),
                        page: PageName.MY_ACCOUNT_ORDER_HISTORY_DETAIL
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'quotes',
            name: PageName.MY_ACCOUNT_QUOTES,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/quote/Quotes.vue'),
            meta: {
                permission: Permission.VIEW_QUOTE_STATUS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Quotes'),
                        page: PageName.MY_ACCOUNT_QUOTES
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'quotes/detail/:quoteId',
            name: PageName.MY_ACCOUNT_QUOTES_DETAIL,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/quote/QuotesDetail.vue'),
            props: true,
            meta: {
                permission: Permission.VIEW_QUOTE_STATUS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Quotes'),
                        page: PageName.MY_ACCOUNT_QUOTES
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.QuotesDetail'),
                        page: PageName.MY_ACCOUNT_QUOTES_DETAIL
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'bids',
            name: PageName.MY_ACCOUNT_BIDS,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/bids/Bids.vue'),
            meta: {
                permission: Permission.VIEW_BID_STATUS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Bids'),
                        page: PageName.MY_ACCOUNT_BIDS
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'blanket-orders',
            name: PageName.MY_ACCOUNT_BLANKET_ORDERS,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/blanket/Blankets.vue'),
            meta: {
                permission: Permission.VIEW_QUOTE_STATUS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.BlanketOrders'),
                        page: PageName.MY_ACCOUNT_BLANKET_ORDERS
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'blanket-orders/detail/:orderId',
            name: PageName.MY_ACCOUNT_BLANKET_DETAIL,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/blanket/BlanketsDetail.vue'),
            props: (route) => ({
                orderId: route.params.orderId
            }),
            meta: {
                permission: Permission.VIEW_QUOTE_STATUS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.BlanketOrders'),
                        page: PageName.MY_ACCOUNT_BLANKET_ORDERS
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.BlanketOrdersDetail'),
                        page: PageName.MY_ACCOUNT_BLANKET_DETAIL
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'rma-returns',
            name: PageName.MY_ACCOUNT_RMA_RETURN,
            component: () => import('@/project/app/client-pages/my-account/rma-returns/RmaReturnsFrontPage.vue'),
            meta: {
                permission: Permission.VIEW_RMA_STATUS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.RMAReturns'),
                        page: PageName.MY_ACCOUNT_RMA_RETURN
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'rma-returns/create/:rmaCreateType?',
            name: PageName.MY_ACCOUNT_RMA_RETURN_CREATE,
            component: () => import('@/project/app/client-pages/my-account/rma-returns/RmaReturnsCreatePage.vue'),
            props: (route) => ({
                rmaCreateType: route.params.rmaCreateType
            }),
            meta: {
                permission: Permission.CREATE_RMA,
                skipAutoPageTracking: true,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.RMAReturns'),
                        page: PageName.MY_ACCOUNT_RMA_RETURN
                    },
                    {
                        name: dictionaryService.get('MyAccount.RmaReturns.CreateRma'),
                        page: PageName.MY_ACCOUNT_RMA_RETURN_CREATE
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'rma-returns/history',
            name: PageName.MY_ACCOUNT_RMA_RETURN_HISTORY,
            component: () => import('@/project/app/client-pages/my-account/rma-returns/RmaReturnsHistoryPage.vue'),
            meta: {
                permission: Permission.VIEW_RMA_STATUS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.RMAReturns'),
                        page: PageName.MY_ACCOUNT_RMA_RETURN
                    },
                    {
                        name: dictionaryService.get('MyAccount.RmaReturns.History'),
                        page: PageName.MY_ACCOUNT_RMA_RETURN_HISTORY
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'rma-returns/history/:rmaId',
            name: PageName.MY_ACCOUNT_RMA_RETURN_HISTORY_DETAIL,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/rma-returns/RmaReturnsHistoryPage.vue'),
            props: (route) => ({
                rmaId: route.params.rmaId
            }),
            meta: {
                permission: Permission.VIEW_RMA_STATUS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.RMAReturns'),
                        page: PageName.MY_ACCOUNT_RMA_RETURN
                    },
                    {
                        name: dictionaryService.get('MyAccount.RmaReturns.History'),
                        page: PageName.MY_ACCOUNT_RMA_RETURN_HISTORY
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'ledger',
            name: PageName.MY_ACCOUNT_LEDGER,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/ledger/Ledger.vue'),
            meta: {
                permission: Permission.VIEW_STATEMENT_OF_ACCOUNT,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Ledger'),
                        page: PageName.MY_ACCOUNT_LEDGER
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'favourites',
            name: PageName.MY_ACCOUNT_FAVOURITES,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/favourites/Favourites.vue'),
            meta: {
                permission: Permission.VIEW_FAVOURITES,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Favourites'),
                        page: PageName.MY_ACCOUNT_FAVOURITES
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'favourites/detail/:listId',
            name: PageName.MY_ACCOUNT_FAVOURITES_DETAIL,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/favourites/FavouritesDetail.vue'),
            props: (route) => ({
                listId: route.params.listId
            }),
            meta: {
                permission: Permission.VIEW_FAVOURITES,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Favourites'),
                        page: PageName.MY_ACCOUNT_FAVOURITES
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.FavouritesDetail'),
                        page: PageName.MY_ACCOUNT_FAVOURITES_DETAIL
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'settings',
            name: PageName.MY_ACCOUNT_ACCOUNT_SETTINGS,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/settings/AccountSettings.vue'),
            meta: {
                permission: Permission.VIEW_ACCOUNT_SETTINGS,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.AccountSettings'),
                        page: PageName.MY_ACCOUNT_ACCOUNT_SETTINGS
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'address-book',
            name: PageName.MY_ACCOUNT_ADDRESS_BOOK,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/address-book/AddressBook.vue'),
            meta: {
                permission: Permission.VIEW_ADDRESSBOOK,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.AddressBook'),
                        page: PageName.MY_ACCOUNT_ADDRESS_BOOK
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'administration',
            name: PageName.MY_ACCOUNT_USER_ADMINISTRATION,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/administration/UserAdministration.vue'),
            meta: {
                permission: Permission.MANAGE_ASSOCIATED_USER,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.UserAdministration'),
                        page: PageName.MY_ACCOUNT_USER_ADMINISTRATION
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'impersonation',
            name: PageName.MY_ACCOUNT_IMPERSONATION,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/impersonation/Impersonate.vue'),
            meta: {
                permission: Permission.IMPERSONATE,
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Impersonation'),
                        page: PageName.MY_ACCOUNT_IMPERSONATION
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'productguideconfiguration',
            name: PageName.MY_ACCOUNT_PRODUCT_GUIDE_CONFIGURATION,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/product-guide-configuration/ProductGuideConfigurationOverview.vue'),
            meta: {
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.ProductGuideConfiguration'),
                        page: PageName.MY_ACCOUNT_PRODUCT_GUIDE_CONFIGURATION
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'productguideconfiguration/edit',
            name: PageName.MY_ACCOUNT_PRODUCT_GUIDE_CONFIGURATION_NEW,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/product-guide-configuration/ProductGuideConfigurationEdit.vue'),
            meta: {
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.ProductGuideConfiguration'),
                        page: PageName.MY_ACCOUNT_PRODUCT_GUIDE_CONFIGURATION
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.ProductGuideConfiguration.Edit'),
                        page: PageName.MY_ACCOUNT_PRODUCT_GUIDE_CONFIGURATION_NEW
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'productguideconfiguration/edit/:externalId',
            name: PageName.MY_ACCOUNT_PRODUCT_GUIDE_CONFIGURATION_EDIT,
            component: () => import(
                /* webpackChunkName: 'myaccount' */
                '@/project/app/client-pages/my-account/product-guide-configuration/ProductGuideConfigurationEdit.vue'),
            props: (route) => ({
                externalId: route.params.externalId
            }),
            meta: {
                headMeta: {},
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.ProductGuideConfiguration'),
                        page: PageName.MY_ACCOUNT_PRODUCT_GUIDE_CONFIGURATION
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.ProductGuideConfiguration.Edit'),
                        page: PageName.MY_ACCOUNT_PRODUCT_GUIDE_CONFIGURATION_EDIT
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'rackconfigurations',
            name: PageName.MY_ACCOUNT_RACK_CONFIGURATIONS,
            component: () => import(
                /* webpackChunkName: 'myaccount/rack' */
                '@/project/app/client-pages/my-account/rack-configurations/MyAccountRackConfigurations.vue'),
            meta: {
                headMeta: {},
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    },
                    {
                        name: dictionaryService.get('MyAccount.Navigation.RackConfigurations'),
                        page: PageName.MY_ACCOUNT_RACK_CONFIGURATIONS
                    }
                ]
            } as RouterMetaData
        },
        {
            path: 'next-delivery',
            name: PageName.MY_ACCOUNT_NEXT_DELIVERY,
            component: () => import(
                /* webpackChunkName: 'myaccount/next-delivery' */
                '@/project/app/client-pages/next-delivery-administration/NextDeliveryCountrySelect.vue'),
            meta: {
                headMeta: {},
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    }
                ],
                isPublic: false,
                hideBreadcrumb: false
            } as RouterMetaData
        },
        {
            path: 'next-delivery/:countryId',
            name: PageName.MY_ACCOUNT_NEXT_DELIVERY_COUNTRY,
            props: (route) => ({
                countryId: route.params.countryId
            }),
            component: () => import(
                /* webpackChunkName: 'myaccount/next-delivery' */
                '@/project/app/client-pages/next-delivery-administration/NextDeliveryOverview.vue'),
            meta: {
                headMeta: {},
                breadcrumb: [
                    {
                        name: dictionaryService.get('MyAccount.Navigation.Title'),
                        page: PageName.MY_ACCOUNT
                    }
                ],
                isPublic: false,
                hideBreadcrumb: false
            } as RouterMetaData
        }
    ]
};

export function permissionForRoute(name: string): Permission | undefined {
    const route = MyAccountRoutes.name === name ? MyAccountRoutes : MyAccountRoutes.children!.find(r => r.name === name);
    return route ? (route.meta as RouterMetaData).permission : undefined;
}
