import tokenService, { UserGroup } from '@/core/auth/token.service';

export enum Permission {
    PLACE_ORDER,
    ADD_DELIVERY_ADDRESS,
    CREATE_RMA,
    VIEW_ORDER_STATUS,
    VIEW_RMA_STATUS,
    VIEW_STATEMENT_OF_ACCOUNT,
    VIEW_ADDRESSBOOK,
    VIEW_MYACCOUNT,
    VIEW_FAVOURITES,
    VIEW_ACCOUNT_SETTINGS,
    VIEW_QUOTE_STATUS,
    VIEW_BID_STATUS,
    MANAGE_ADDRESSBOOK,
    MANAGE_ASSOCIATED_USER,
    IMPERSONATE,
    PUNCHOUT,
    VIEW_NEXT_DELIVERY
}

const permissionMapping = new Map<Permission, Array<UserGroup>>([
    [Permission.PLACE_ORDER, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted, UserGroup.Normal, UserGroup.PunchOut]],
    [Permission.ADD_DELIVERY_ADDRESS, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted]],
    [Permission.VIEW_ORDER_STATUS, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted, UserGroup.Support, UserGroup.Normal]],
    [Permission.VIEW_QUOTE_STATUS, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted, UserGroup.Support]],
    [Permission.VIEW_BID_STATUS, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted, UserGroup.Normal, UserGroup.Support, UserGroup.Viewer]],
    [Permission.VIEW_RMA_STATUS, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted, UserGroup.Normal, UserGroup.Support, UserGroup.Viewer]],
    [Permission.CREATE_RMA, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted, UserGroup.Normal, UserGroup.Support]],
    // VIEW_STATEMENT_OF_ACCOUNT is better known as Ledger
    [Permission.VIEW_STATEMENT_OF_ACCOUNT, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted]],
    [Permission.VIEW_ADDRESSBOOK, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted]],
    [Permission.VIEW_MYACCOUNT, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted, UserGroup.Normal, UserGroup.Support, UserGroup.Viewer]],
    [Permission.VIEW_FAVOURITES, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted, UserGroup.Normal, UserGroup.Support, UserGroup.Viewer]],
    [Permission.VIEW_ACCOUNT_SETTINGS, [UserGroup.System, UserGroup.Administrator, UserGroup.Trusted, UserGroup.Normal, UserGroup.Support, UserGroup.Viewer]],
    [Permission.MANAGE_ADDRESSBOOK, [UserGroup.System, UserGroup.Administrator]],
    [Permission.MANAGE_ASSOCIATED_USER, [UserGroup.System, UserGroup.Administrator]],
    [Permission.PUNCHOUT, [UserGroup.PunchOut]],
    [Permission.VIEW_NEXT_DELIVERY, [UserGroup.System]]
]);

function getUserGroup() {
    return tokenService.getUserData()?.role ?? null;
}

function can(permission: Permission): boolean {
    const userGroup = getUserGroup();
    if (!userGroup) return false;

    const rolebasedPermissionResult = permissionMapping.get(permission)?.includes(UserGroup[userGroup]) ?? false;
    return postProcessPermission(permission, rolebasedPermissionResult);
}

function getPermissionMapping() {
    return permissionMapping;
}

function postProcessPermission(permission: Permission, rolebasedPermissionResult: boolean): boolean {
    switch (permission) {
    case Permission.PLACE_ORDER: {
        const customerCanLoginButCannotPlaceOrder = tokenService.getUserData()?.blocking === 'CanLoginButCannotPlaceOrder';
        return rolebasedPermissionResult && !customerCanLoginButCannotPlaceOrder;
    }
    case Permission.IMPERSONATE: {
        return (tokenService.getUserData()?.impersonate === '1' ?? false) || (tokenService.getUserData()?.impersonatorEmail != null ?? false);
    }
    default:
        return rolebasedPermissionResult;
    }
}

function canPlaceOrder() {
    return can(Permission.PLACE_ORDER);
}

function canAddDeliveryAddress() {
    return can(Permission.ADD_DELIVERY_ADDRESS);
}

function canViewBids() {
    return can(Permission.VIEW_BID_STATUS);
}

function canViewOrderStatus() {
    return can(Permission.VIEW_ORDER_STATUS);
}

function canViewRMAStatus() {
    return can(Permission.VIEW_RMA_STATUS);
}

function canCreateRMA() {
    return can(Permission.CREATE_RMA);
}

function canViewStatementOfAccount() {
    return can(Permission.VIEW_STATEMENT_OF_ACCOUNT);
}

function canViewAddressBook() {
    return can(Permission.VIEW_ADDRESSBOOK);
}

function canViewFavourites(): boolean {
    return can(Permission.VIEW_FAVOURITES);
}

function canViewAccountSettings(): boolean {
    return can(Permission.VIEW_ACCOUNT_SETTINGS);
}

function canViewMyAccount(): boolean {
    return can(Permission.VIEW_MYACCOUNT);
}

function canManageAddressBook() {
    return can(Permission.MANAGE_ADDRESSBOOK);
}

function canManageAssociatedUsers() {
    return can(Permission.MANAGE_ASSOCIATED_USER);
}

function canImpersonate(): boolean {
    return can(Permission.IMPERSONATE);
}

function canPunchOut(): boolean {
    return can(Permission.PUNCHOUT);
}
function canViewQuotes(): boolean {
    return can(Permission.VIEW_QUOTE_STATUS);
}

function isStaff(): boolean {
    return tokenService.getUserData()?.staff === '1';
}

function isNormal(): boolean {
    return tokenService.getUserData()?.role === UserGroup.Normal;
}

function isViewer(): boolean {
    return tokenService.getUserData()?.role === UserGroup.Viewer;
}

export default {
    canPlaceOrder,
    canAddDeliveryAddress,
    canViewBids,
    canViewOrderStatus,
    canViewRMAStatus,
    canCreateRMA,
    canViewStatementOfAccount,
    canViewAddressBook,
    canViewMyAccount,
    canViewFavourites,
    canViewAccountSettings,
    canManageAddressBook,
    canManageAssociatedUsers,
    canImpersonate,
    canPunchOut,
    canViewQuotes,
    isStaff,
    isNormal,
    isViewer,
    can,
    getPermissionMapping
};

declare module 'vue/types/vue' {
    interface Vue {
        $permission: {
            canPlaceOrder: () => boolean,
            canCreateOrder: () => boolean,
            canAddDeliveryAddress: () => boolean,
            canViewBids: () => boolean,
            canViewOrderStatus: () => boolean,
            canViewRMAStatus: () => boolean,
            canViewQuotes: () => boolean,
            canCreateRMA: () => boolean,
            canViewStatementOfAccount: () => boolean,
            canViewAddressBook: () => boolean,
            canViewMyAccount: () => boolean,
            canViewFavourites: () => boolean,
            canViewAccountSettings: () => boolean,
            canCreateOrDeleteUsers: () => boolean,
            canManageAddressBook: () => boolean,
            canManageAssociatedUsers: () => boolean,
            canImpersonate: () => boolean,
            canPunchOut: () => boolean,
            isStaff: () => boolean,
            can: (permission: Permission) => boolean
        };
    }
}
