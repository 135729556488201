import { AccountController } from './controllers/accountController';
import { AccessController } from './controllers/accessController';
import { AuthenticationController } from './controllers/authenticationController';
import { SitesController } from './controllers/sitesController';
import { UserController } from './controllers/userController';
import { CustomerController } from './controllers/customerController';
import { SearchController } from './controllers/searchController';
import { NavigationController } from './controllers/navigationController';
import { NewsletterController } from './controllers/newsletterController';
import { ProductController } from './controllers/productController';
import { ProductGuideController } from './controllers/productGuideController';
import { PriceController } from './controllers/priceController';
import { StockController } from './controllers/stockController';
import { PersonalizationController } from './controllers/personalizationController';
import { CheckoutController } from './controllers/checkoutController';
import { DeliveryController } from './controllers/deliveryController';
import { BrandController } from './controllers/brandController';
import { RmaController } from './controllers/rmaController';
import { ImpersonationController } from './controllers/impersonationController';
import { MetadataController } from './controllers/metadataController';
import { FavoritesController } from './controllers/favoritesController';
import { BasketController } from './controllers/basketController';
import { RecommendationsController } from './controllers/recommendationsController';
import { CableGuideController } from './controllers/cableGuideController';
import { ProductGuideConfigurationController } from '@/project/http/controllers/productGuideConfigurationController';
import { NotificationsController } from './controllers/notificationsController';
import { EmployeeController } from './controllers/employeeController';
import { CvrSearchController } from './controllers/cvrSearchController';
import { CategoryController } from './controllers/categoryController';
import { MaintenanceController } from './controllers/mainteanceController';
import { TranslateController } from './controllers/translateController';
import { BecomeAUserController } from '@/project/http/controllers/becomeAUserController';
import { OciController } from '@/project/http/controllers/OciController';
import { NextDeliveryConfigurationController } from '@/project/http/controllers/nextDeliveryConfigController';
import { NextDeliveryController } from '@/project/http/controllers/nextDeliveryController';
import { RackController } from '@/project/http/controllers/rackController';
import { CommonFunctionsController } from '@/project/http/controllers/commonFunctionsController';

export default class Api {
    public static account = new AccountController();
    public static access = new AccessController();
    public static authentication = new AuthenticationController();
    public static becomeAUser = new BecomeAUserController();
    public static commonFunctions = new CommonFunctionsController();
    public static category = new CategoryController();
    public static sites = new SitesController();
    public static user = new UserController();
    public static customer = new CustomerController();
    public static search = new SearchController();
    public static navigation = new NavigationController();
    public static newsletter = new NewsletterController();
    public static product = new ProductController();
    public static productGuide = new ProductGuideController();
    public static productGuideConfiguration = new ProductGuideConfigurationController();
    public static price = new PriceController();
    public static stock = new StockController();
    public static personalization = new PersonalizationController();
    public static basket = new BasketController();
    public static checkout = new CheckoutController();
    public static delivery = new DeliveryController();
    public static brand = new BrandController();
    public static rma = new RmaController();
    public static impersonation = new ImpersonationController();
    public static metadata = new MetadataController();
    public static favorites = new FavoritesController();
    public static recommendations = new RecommendationsController();
    public static cableGuide = new CableGuideController();
    public static notifications = new NotificationsController();
    public static employee = new EmployeeController();
    public static cvrSearch = new CvrSearchController();
    public static maintenance = new MaintenanceController();
    public static translate = new TranslateController();
    public static oci = new OciController();
    public static nextDelivery = new NextDeliveryController();
    public static nextDeliveryConfig = new NextDeliveryConfigurationController();
    public static rack = new RackController();
}
