function ensureAnchorId(anchor?: string) {
    // replace # in id to avoid id="#my-element" that makes the selector $$('#\#my-element')
    return anchor ? CSS.escape(anchor.replace('#', '')) : undefined;
}

export default {
    install(Vue) {
        Vue.prototype.$anchorFilter = { get: ensureAnchorId };
    }
};

declare module 'vue/types/vue' {
    interface Vue {
        $anchorFilter: { get: (obj?: string) => string };
    }
}
