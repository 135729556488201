
import Vue, { CreateElement } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import urlStringHelperService from '@/core/urlStringHelper.service';

@Component
export default class CultureAwareRouterLink extends Vue {
    @Prop({ type: [Object, String], default: '' }) to!: RawLocation;
    @Prop({ type: String }) target!: string;

    render(h: CreateElement) {
        // If href / to is missing, render plain <DIV>
        if (!this.to) {
            return h('div', {
                attrs: { ...this.$attrs }
            }, this.$slots.default);
        }
        const isExternalLink = urlStringHelperService.isOutgoingUrl(this.to);
        const linkTag = isExternalLink ? 'a' : 'router-link';

        const attrs = {
            ...this.$attrs,
            ...(!isExternalLink && { to: fixupUrl(this.to), target: this.target }),
            ...(isExternalLink && { href: this.to, target: '_blank', rel: 'noopener' })
        };

        const listeners = isExternalLink
            ? {
                // For 'a' tag, combine normal and native listeners (not allowed to put native events (e.g. click.native) on 'a' tag)
                on: {
                    ...this.$listeners,
                    ...this.$vnode?.data?.nativeOn
                }
            } : {
                on: this.$listeners
            };

        return h(linkTag, {
            attrs: attrs,
            ...listeners
        }, this.$slots.default);

        function fixupUrl(to: RawLocation): RawLocation {
            let path = urlStringHelperService.isLocation(to) ? to.path : to;
            let hash: string | undefined;
            if (path) {
                let { path: tmp, hash: tmpHash } = urlStringHelperService.removeTrailingSlashAndLowercase(path);
                if (urlStringHelperService.isFrontpagePath(tmp)) {
                    return '/' + (tmpHash || '');
                }
                // Internal links with protocol and host header
                if (!urlStringHelperService.isOutgoingUrl(tmp) && (tmp.indexOf('http://') === 0 || tmp.indexOf('https://') === 0)) {
                    const tmpRemoveHostFromInternalLinks = urlStringHelperService.removeHostFromInternalLinks(tmp);
                    if (tmpRemoveHostFromInternalLinks) {
                        tmp = tmpRemoveHostFromInternalLinks;
                    }
                }

                path = urlStringHelperService.hasCulturePrefix(tmp) ? urlStringHelperService.withoutCulturePrefix(tmp) : tmp;
                hash = tmpHash;
            }

            if (!urlStringHelperService.isLocation(to)) {
                const [tmpPath, tmpQuery] = (path || '').split('?');
                return (tmpPath || '') + (tmpQuery ? ('?' + tmpQuery) : '') + (hash ? '#' + hash : '');
            }

            const params = {
                ...to.params
            };
            for (const param in params) {
                params[param] = params[param].toLowerCase();
            }

            return {
                ...to,
                path,
                params,
                hash
            };
        }
    }
}
