const tailwind = {
    target: 'ie11',

    theme: {
        screens: require('./screens'),
        colors: {
            currentcolor: 'currentColor',
            transparent: 'transparent',
            black: '#262422',
            opremablack: '#393839',
            white: '#ffffff',
            gray: {
                250: '#F9F9F9',
                300: '#F6F6F6',
                350: '#E6E6E6',
                400: '#EDEDED',
                450: '#dddddd',
                475: '#D8D8D8',
                500: '#DCDCDC',
                700: '#B2B2B2',
                720: '#C7C5C5',
                750: '#AAA',
                800: '#959595',
                825: '#7b7b7b',
                850: '#5c5b59',
                900: '#4C4C4C'
            },
            opremagray: {
                300: '#b2b2b2'
            },
            blue: {
                100: '#E8EDF3',
                300: '#C5D2E0',
                400: '#E5EAEF',
                425: '#AFC1D4',
                450: '#6C87AE',
                500: '#3062A9',
                600: '#264E87',
                700: '#122245'
            },
            opremablue: {
                75: '#afd0d9',
                500: '#003349'
            },
            opremayellow: {
                500: '#ffed00'
            },
            green: {
                200: '#E2EBE8',
                300: '#9DBCB0',
                400: '#89C081',
                450: '#627C75',
                500: '#006A4D'
            },
            red: {
                100: '#F2E7E7',
                300: '#C55D5D',
                500: '#d94949',
                700: '#A34646'
            },
            brown: {
                500: '#BDB1A6'
            },
            yellow: {
                500: '#FFCA56',
                600: '#DBA16D'
            }
        },
        textColor: theme => theme('colors'),
        fill: theme => theme('colors'),
        backgroundColor: theme => theme('colors'),
        borderColor: theme => theme('colors'),
        boxShadow: {
            input: 'inset 0px 1px 2px #00000020',
            'brand-guide': '0 1rem 3rem #00000014',
            'rma-entrance': '0px 10px 30px #00000014'
        },
        borderWidth: {
            default: '0.1rem',
            0: '0',
            '1h': '0.15rem',
            2: '0.2rem',
            3: '0.3rem',
            6: '0.6rem',
            24: '2.4rem'
        },
        borderRadius: {
            none: '0',
            sm: '0.125rem',
            default: '0.25rem',
            2: '0.2rem',
            4: '0.4rem',
            7: '0.7rem',
            8: '0.8rem',
            md: '0.375rem',
            lg: '0.5rem',
            xl: '0.75rem',
            '2xl': '1rem',
            '3xl': '1.5rem',
            30: '3rem',
            half: '50%',
            full: '9999px'
        },
        fontSize: {
            9: '0.9rem',
            10: '1.0rem',
            11: '1.1rem',
            12: '1.2rem',
            13: '1.3rem',
            14: '1.4rem',
            15: '1.5rem',
            16: '1.6rem',
            18: '1.8rem',
            20: '2.0rem',
            22: '2.2rem',
            24: '2.4rem',
            32: '3.2rem',
            34: '3.4rem',
            36: '3.6rem',
            46: '4.6rem',
            50: '5rem',
            64: '6.4rem'
        },
        height: theme => ({
            auto: 'auto',
            0: '0',
            4: '0.4rem',
            5: '0.5rem',
            10: '1rem',
            12: '1.2rem',
            16: '1.6rem',
            18: '1.8rem',
            20: '2rem',
            23: '2.3rem',
            25: '2.5rem',
            30: '3rem',
            32: '3.2rem',
            34: '3.4rem',
            35: '3.5rem',
            36: '3.6rem',
            40: '4rem',
            50: '5rem',
            53: '5.3rem',
            60: '6rem',
            70: '7rem',
            80: '8rem',
            100: '10rem',
            120: '12rem',
            150: '15rem',
            160: '16rem',
            full: '100%',
            'screen-half': '50vh',
            screen: '100vh'
        }),
        minHeight: theme => ({
            auto: 'auto',
            '3/4': '75%',
            full: '100%',
            screen: '100vh',
            15: '1.5rem',
            19: '1.9rem',
            22: '2.2rem',
            30: '3rem',
            32: '3.2rem',
            34: '3.4rem',
            43: '4.3rem',
            50: '5rem',
            60: '6rem',
            80: '8rem',
            90: '9rem',
            100: '10rem',
            120: '12rem',
            125: '12.5rem',
            140: '14rem',
            200: '20rem',
            250: '25rem',
            280: '28rem',
            310: '31rem',
            350: '35rem',
            400: '40rem',
            450: '45rem',
            480: '48rem',
            0: '0'
        }),
        width: theme => ({
            auto: 'auto',
            '1/2': '50%',
            '1/3': `${100 / 3}%`,
            '2/3': `${200 / 3}%`,
            '1/5': '20%',
            '2/5': '40%',
            '3/5': `${300 / 5}%`,
            '4/5': `${400 / 5}%`,
            '1/4': '25%',
            '2/4': '50%',
            '3/4': '75%',
            '1/12': `${100 / 12}%`,
            '2/12': `${200 / 12}%`,
            '3/12': `${300 / 12}%`,
            '10/12': `${1000 / 12}%`,
            '1/6': `${100 / 6}%`,
            '7/20': '35%',
            '13/20': '65%',
            1: '0.1rem',
            10: '1rem',
            12: '1.2rem',
            16: '1.6rem',
            20: '2rem',
            25: '2.5rem',
            30: '3rem',
            32: '3.2rem',
            34: '3.4rem',
            40: '4rem',
            50: '5rem',
            60: '6rem',
            64: '6.4rem',
            70: '7rem',
            80: '8rem',
            100: '10rem',
            160: '16rem',
            300: '30rem',
            400: '40rem',
            800: '80rem',
            full: '100%',
            screen: '100vw'
        }),
        padding: {
            0: '0',
            1: '0.1rem',
            2: '0.2rem',
            3: '0.3rem',
            4: '0.4rem',
            5: '0.5rem',
            6: '0.6rem',
            7: '0.7rem',
            8: '0.8rem',
            9: '0.9rem',
            10: '1rem',
            12: '1.2rem',
            13: '1.3rem',
            15: '1.5rem',
            16: '1.6rem',
            17: '1.7rem',
            18: '1.8rem',
            20: '2rem',
            22: '2.2rem',
            25: '2.5rem',
            30: '3rem',
            32: '3.2rem',
            35: '3.5rem',
            40: '4rem',
            48: '4.8rem',
            50: '5rem',
            60: '6rem',
            70: '7rem',
            80: '8rem',
            100: '10rem',
            '1/12': `${100 / 12}%`
        },
        margin: {
            auto: 'auto',
            '-2': '-0.2rem',
            '-3': '-0.3rem',
            '-4': '-0.4rem',
            '-8': '-0.8rem',
            '-10': '-1rem',
            '-12': '-1.2rem',
            '-20': '-2rem',
            '-30': '-3rem',
            '-40': '-4rem',
            0: '0',
            1: '0.1rem',
            2: '0.2rem',
            3: '0.3rem',
            4: '0.4rem',
            5: '0.5rem',
            6: '0.6rem',
            7: '0.7rem',
            8: '0.8rem',
            9: '0.9rem',
            10: '1rem',
            12: '1.2rem',
            14: '1.4rem',
            15: '1.5rem',
            16: '1.6rem',
            17: '1.7rem',
            20: '2rem',
            22: '2.2rem',
            24: '2.4rem',
            25: '2.5rem',
            30: '3rem',
            35: '3.5rem',
            40: '4rem',
            45: '4.5rem',
            50: '5rem',
            60: '6rem',
            70: '7rem',
            90: '9rem',
            110: '11rem',
            120: '12rem',
            '1/12': `${100 / 12}%`,
            '2/12': `${200 / 12}%`,
            '3/12': `${300 / 12}%`
        },
        maxHeight: {
            full: '100%',
            screen: '100vh',
            initial: 'initial',
            35: '3.5rem',
            40: '4rem',
            60: '6rem',
            65: '6.5rem',
            215: '21.5rem'
        },
        maxWidth: {
            full: '100%',
            '1/2': '50%',
            '2/3': '66%',
            '1/3': '33%',
            '1/4': '25%',
            '1/5': '20%',
            'page-max': '144rem',
            66: '6.6rem',
            70: '7rem',
            100: '10rem',
            135: '13.5rem',
            150: '15rem',
            155: '15.5rem',
            170: '17rem',
            210: '21rem',
            250: '25rem',
            350: '35rem',
            420: '42rem',
            500: '50rem',
            600: '60rem',
            800: '80rem',
            850: '85rem'
        },
        lineHeight: {
            none: '1',
            normal: '1.2',
            large: '1.4',
            double: '2',
            'body-desktop': '2.2rem',
            40: '4rem'
        },
        zIndex: {
            '-1': -1,
            0: '0',
            1: '1',
            2: '2',
            3: '3',

            header: '10',
            facetOverlay: '11',
            facetSelector: '12',
            facetSpinner: '13',
            overlay: '20',
            'overlay-2': '21',
            errorToaster: '30'
        },
        fontFamily: {
            montserrat: ['Montserrat', 'sans-serif']
        },
        fontWeight: {
            regular: 400,
            medium: 500,
            'semi-bold': 600,
            bold: 700,
            'oprema-ultra-light': 100,
            'oprema-bold': 900
        },
        inset: {
            100: '10rem',
            70: '7rem',
            45: '4.5rem',
            30: '3rem',
            20: '2rem',
            10: '1rem',
            5: '0.5rem',
            2: '2px',
            1: '1px',
            '075': '0.75rem',
            0: 0,
            '-4': '-4px',
            '-5': '-5px',
            '-10': '-1rem',
            '-50': '-5rem',
            '-60': '-6rem',
            '1/2': '50%',
            '1/3': '33%',
            full: '100%'
        },
        opacity: {
            0: '0',
            15: '0.15',
            20: '0.20',
            25: '0.25',
            30: '0.3',
            40: '0.4',
            50: '0.5',
            75: '0.75',
            80: '0.80',
            100: '1'
        },
        extend: {
            minWidth: theme => ({
                15: '1.5rem',
                16: '1.6rem',
                20: '2rem',
                25: '2.5rem',
                30: '3rem',
                50: '5rem',
                80: '8rem',
                90: '9rem',
                100: '10rem',
                120: '12rem',
                140: '14rem',
                170: '17rem',
                200: '20rem',
                300: '30rem',
                '1/2': '50%',
                '1/3': `${100 / 3}%`,
                '2/3': `${200 / 3}%`,
                'min-content': 'min-content',
                'screen-80': '80vw'
            }),
            boxShadow: {
                topBarSmallMenu: '0px 10px 30px #00000029',
                productGuideBlock: '0px 10px 30px #00000014'
            }
        }
    },

    variants: {
        objectFit: ['responsive'],
        margin: ['responsive', 'first', 'last', 'oprema'],
        padding: ['responsive', 'first', 'last', 'oprema'],
        textColor: ['responsive', 'hover', 'oprema'],
        backgroundColor: ['responsive', 'hover', 'oprema'],
        borderColor: ['oprema'],
        display: ['responsive'],
        fontWeight: ['responsive', 'oprema'],
        placeContent: ['responsive']
    },

    corePlugins: {
        float: false,
        objectFit: true,
        objectPosition: true,
        container: false,
        backgroundAttachment: false,
        gap: false,
        gridAutoFlow: false,
        gridColumn: false,
        gridColumnStart: false,
        gridColumnEnd: false,
        gridRow: false,
        gridRowStart: false,
        gridRowEnd: false,
        gridTemplateColumns: false,
        gridTemplateRows: false,
        display: true
    },
    plugins: require('./plugins'),
    experimental: {
        applyComplexClasses: true
    }
};

/*
|-----------------------------------------------------------------------------
| Global variables
|-----------------------------------------------------------------------------
|
| Variables for use in custom less like this
| width: config('variables.canvasNavWidth')
|
*/
tailwind.variables = require('./0-Settings/variables')(tailwind);

// Export
module.exports = tailwind;
