// Only stuff needed during bootstrapping (before Vue is loaded)
import tokenService from '@/core/auth/token.service';

declare global {
    interface Window {
        dataLayer: Array<DataLayerEntry>,
    }
}

export type DataLayerEntry = { [key: string]: any };

class GTMBasics {
    public trackUserLogin(): void {
        const userData = tokenService.getUserData(true);
        const payload = {
            event: 'user.login',
            language: window.servercontext.languageCode,
            country: window.servercontext.countryCode,
            companyName: window.servercontext.businessEntityName,

            ...(userData !== null && {
                contactKey: userData.contactKey,
                contactHash: userData.contactHash,
                customerId: userData.customerNo,
                webUserId: userData.webUserId,
                customInfo2: userData.salesCategory,
                ...(userData.impersonatorEmail && { impersonated: 'true' }),
                role: userData.role,
                segment: userData.userSegment || undefined
            })
        };

        this.pushToDataLayer(payload);
    }

    public init() {
        window.dataLayer = [];
        // Insert userlogin event _before_ GTM script is initialized
        this.trackUserLogin();
        this.runGTMScript();
    }

    public pushToDataLayer(trackingObject: DataLayerEntry): void {
        window.dataLayer && window.dataLayer.push(trackingObject);
    }

    private runGTMScript() {
        const script = document.createElement('script');
        script.text =
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', '${window.servercontext.googleTagManagerId}');
`;
        document.head.appendChild(script);
    }
}

export default new GTMBasics();
